<template>
    <a-card :bordered="false" class="card-signin header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
        <div class="pb-5">
            <!-- Sign In Form Column -->
            <div class="logo">
                <img src="../../assets/images/logo.jpg" alt="logo" />
            </div>

            <h4 class="mb-5 text-center">{{ $t('sign_in.title') }}</h4>

            <!-- Sign In Form -->
            <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form"
                @submit="handleSubmit"
                :hideRequiredMark="true"
            >
                <a-form-item :label="$t('form.email')" :colon="false">
                    <a-input
                        v-decorator="[
                            'email',
                            {
                                rules: [
                                    { required: true, message: $t('form.email_required') },
                                    { type: 'email', message: $t('form.incorrect_email_format') },
                                ],
                            },
                        ]"
                        :placeholder="$t('form.email')"
                    />
                </a-form-item>
                <a-form-item :label="$t('form.password')" :colon="false">
                    <a-input-password
                        v-decorator="[
                            'password',
                            {
                                rules: [
                                    { required: true, message: $t('form.password_required') },
                                    { validator: checkPassword },
                                ],
                            },
                        ]"
                        type="password"
                        :placeholder="$t('form.password')"
                    />
                </a-form-item>
                <a-form-item class="mt-5 mb-0">
                    <a-button type="primary" block html-type="submit" class="login-form-button text-uppercase">
                        {{ $t('sign_in.title') }}
                    </a-button>
                </a-form-item>
            </a-form>
            <!-- / Sign In Form -->

            <div v-if="!isLoginMaster">
                <div class="signIn-or">
                    <div>
                        <span>OR</span>
                    </div>
                </div>

                <button type="primary" class="btn-ms365" @click="handleLogin365">
                    <img src="../../assets/images/logo-ms365.png" alt="logo-ms365.png" />
                    <span>Sign in with Microsoft 365</span>
                </button>

                <!-- Forgot Password -->
                <p class="font-semibold text-muted text-center">
                    <router-link :to="{ path: `/forgot/${companyCode}` }" class="font-bold text-dark">
                        {{ $t('sign_in.forgot_password') }}
                    </router-link>
                </p>
                <!-- / Forgot Password -->

                <!-- <p class="font-semibold text-muted text-center">
                    {{ $t('sign_in.unregistered') }}
                    <router-link :to="{ path: `/sign-up/${companyCode}` }" class="font-bold text-dark">{{
                        $t('sign_up.title')
                    }}</router-link>
                </p> -->
            </div>
            <!-- / Sign In Form Column -->
        </div>
    </a-card>
</template>

<script>
import md from 'md5';
import * as AuthAPI from '../../api/authAPI';
import { HTTP } from '../../config/http-common';
import { mixinToasts, mixinLoadings } from '../../mixins';
import { checkIsManager, checkIsMaster, isMobile } from '../../utils';

export default {
    mixins: [mixinToasts, mixinLoadings],
    components: {},
    data() {
        return {
            form: this.$form.createForm(this, { name: 'login-form' }),
            companyCode: this.$route.params.company_code,
            isLoginMaster: this.$route.path === '/admin',
        };
    },
    methods: {
        checkPassword(rule, value, callback) {
            const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
            if (value && !pattern.test(value)) {
                callback(this.$t('form.incorrect_password_format'));
            } else {
                callback();
            }
        },

        handleNavigate(userInfo) {
            if (isMobile()) {
                this.$router.push('/my-page');
            } else {
                if (checkIsMaster(userInfo) || checkIsManager(userInfo)) {
                    this.$router.push('/dashboard');
                } else {
                    this.$router.push('/my-page');
                }
            }
        },

        // Handles input validation after submission.
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.showLoading();
                    let body = {
                        ...values,
                        password: md(values.password),
                    };
                    const routeAPI = this.isLoginMaster ? '/auth/employee_login' : '/auth/login';

                    if (!this.isLoginMaster && this.companyCode) {
                        body.company_code = this.companyCode;
                    }

                    HTTP.post(routeAPI, body)
                        .then((res) => {
                            let userInfo = {
                                ...res?.data?.results?.object,
                            };

                            if (!this.isLoginMaster && this.companyCode) {
                                userInfo.company_code = this.companyCode;
                            }

                            this.$store.dispatch('login', userInfo);
                            setTimeout(() => {
                                this.hideLoading();
                                this.handleNavigate(userInfo?.result);
                            }, 500);
                        })
                        .catch((error) => {
                            this.hideLoading();
                            this.showError(error);
                        });
                }
            });
        },

        async handleLogin365() {
            try {
                this.showLoading();
                sessionStorage.setItem('companyCode', this.companyCode);
                const res = await AuthAPI.loginBy365();
                window.location.replace(res?.results?.object?.url);
            } catch (error) {
                this.hideLoading();
                this.showError(error);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.card-signin {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
    border-radius: 12px;
    & .logo {
        text-align: center;
        margin-bottom: 30px;
    }
    & .logo img {
        width: 250px;
    }
    & .ant-form-item {
        margin-bottom: 8px;
    }
}

.signIn-or {
    padding: 30px 0;
    text-align: center;
    line-height: 1;
    & span {
        font-size: 14px;
        line-height: 1;
    }
    & > div {
        position: relative;
    }
    & > div::before,
    & > div::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        width: calc(50% - 20px);
        background-color: #d8d8d8;
    }
    & > div::before {
        left: 0;
    }
    & > div::after {
        right: 0;
    }
}

.btn-ms365 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 5px 10px;
    margin-bottom: 30px;
    transition: all 0.4s ease;
    & img {
        width: 30px;
        margin-right: 5px;
    }
    & span {
        font-size: 14px;
    }
    &:hover {
        border-color: #ff5622;
    }
}
</style>
