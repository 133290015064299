var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"card-signin header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0 }}},[_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/images/logo.jpg"),"alt":"logo"}})]),_c('h4',{staticClass:"mb-5 text-center"},[_vm._v(_vm._s(_vm.$t('sign_in.title')))]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.email'),"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'email',
                        {
                            rules: [
                                { required: true, message: _vm.$t('form.email_required') },
                                { type: 'email', message: _vm.$t('form.incorrect_email_format') } ],
                        } ]),expression:"[\n                        'email',\n                        {\n                            rules: [\n                                { required: true, message: $t('form.email_required') },\n                                { type: 'email', message: $t('form.incorrect_email_format') },\n                            ],\n                        },\n                    ]"}],attrs:{"placeholder":_vm.$t('form.email')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('form.password'),"colon":false}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'password',
                        {
                            rules: [
                                { required: true, message: _vm.$t('form.password_required') },
                                { validator: _vm.checkPassword } ],
                        } ]),expression:"[\n                        'password',\n                        {\n                            rules: [\n                                { required: true, message: $t('form.password_required') },\n                                { validator: checkPassword },\n                            ],\n                        },\n                    ]"}],attrs:{"type":"password","placeholder":_vm.$t('form.password')}})],1),_c('a-form-item',{staticClass:"mt-5 mb-0"},[_c('a-button',{staticClass:"login-form-button text-uppercase",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('sign_in.title'))+" ")])],1)],1),(!_vm.isLoginMaster)?_c('div',[_c('div',{staticClass:"signIn-or"},[_c('div',[_c('span',[_vm._v("OR")])])]),_c('button',{staticClass:"btn-ms365",attrs:{"type":"primary"},on:{"click":_vm.handleLogin365}},[_c('img',{attrs:{"src":require("../../assets/images/logo-ms365.png"),"alt":"logo-ms365.png"}}),_c('span',[_vm._v("Sign in with Microsoft 365")])]),_c('p',{staticClass:"font-semibold text-muted text-center"},[_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":{ path: ("/forgot/" + _vm.companyCode) }}},[_vm._v(" "+_vm._s(_vm.$t('sign_in.forgot_password'))+" ")])],1)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }